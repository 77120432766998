import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '@smarttask-common/src/lib/services/globals';
import { OrganizationlistStoreService } from '../../Organization/store/organizationlistStore.service';
import { CallModel } from '@smarttask-common/src/lib/models/CRM/Call/Recording/CallModel';
import { CalllistStoreService } from './store/callStore.service';
import { CallFilterModel } from '@smarttask-common/src/lib/models/CRM/Call/Recording/CallFilterModel';
import { CallUIService } from './callUI.service';
import { CallAnalyticsModel } from '@smarttask-common/src/lib/models/CRM/Call/Recording/Analytics/CallAnalyticsModel';
import { SharedService } from '../../AngularSpecific/shared.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';

@Injectable({
    providedIn: 'root',
})
export class CallService {
    constructor(
        private http: HttpClient,
        private globals: Globals,
        private sharedService: SharedService,
        private organizationlistStoreService: OrganizationlistStoreService,
        private calllistStoreService: CalllistStoreService,
        private callUIService: CallUIService,
    ) {
        this.listenForNewCalls();
    }

    fetchCall = (call_id: number) => {
        let organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        return this.http
            .get(`${this.globals.api_base_url}${this.globals.api_version}/call/${organization_id}/${call_id}`)
            .toPromise()
            .then((call: CallModel) => {
                call = this.callUIService.callReceivedProcessing(call);
                this.calllistStoreService.upsertOne(call);
                return call;
            });
    };

    fetchCallList = (model: CallFilterModel, page_no = 1, list_size = 20) => {
        let post_model = this.callUIService.filterPreSendProcessing(model);
        let organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        return this.http
            .post(
                `${this.globals.api_base_url}${this.globals.api_version}/call/call-list/${organization_id}?page_no=${page_no}&list_size=${list_size}`,
                post_model,
            )
            .toPromise()
            .then((data: any) => {
                let calls: CallModel[] = data.list;
                calls = calls.map((x) => this.callUIService.callReceivedProcessing(x));
                this.calllistStoreService.upsertMany(calls);
                return { list: calls, next_page_no: data.next_page_no, limit_reached: data.limit_reached };
            });
    };

    fetchAnalytics = (model: CallFilterModel) => {
        let post_model = this.callUIService.filterPreSendProcessing(model);
        let organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        return this.http
            .post(`${this.globals.api_base_url}${this.globals.api_version}/call/call-analytics/${organization_id}`, post_model)
            .toPromise()
            .then((analytics_model: CallAnalyticsModel) => {
                return analytics_model;
            });
    };

    listenForNewCalls = () => {
        this.sharedService.on(
            SharedEventNameEnum.call_new,
            (e: SharedEventModel) => {
                let call_id: number = e.obj.call_id;
                this.fetchCall(call_id);
            }
        );
    };
}
